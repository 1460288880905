@tailwind base;
@tailwind components;
@font-face {
    font-family: IRANSansXFaNum;
    font-style: normal;
    font-weight: 100;
    src: url('assets/fonts/Woff/IRANSansXFaNum-Thin.woff') format('woff'),
    url('assets/fonts/Woff2/IRANSansXFaNum-Thin.woff2') format('woff2');
}

@font-face {
    font-family: IRANSansXFaNum;
    font-style: normal;
    font-weight: 200;
    src: url('assets/fonts/Woff/IRANSansXFaNum-UltraLight.woff') format('woff'),
    url('assets/fonts/Woff2/IRANSansXFaNum-UltraLight.woff2') format('woff2');
}

@font-face {
    font-family: IRANSansXFaNum;
    font-style: normal;
    font-weight: 300;
    src: url('assets/fonts/Woff/IRANSansXFaNum-Light.woff') format('woff'),
    url('assets/fonts/Woff2/IRANSansXFaNum-Light.woff2') format('woff2');
}

@font-face {
    font-family: IRANSansXFaNum;
    font-style: normal;
    font-weight: 500;
    src: url('assets/fonts/Woff/IRANSansXFaNum-Medium.woff') format('woff'),
    url('assets/fonts/Woff2/IRANSansXFaNum-Medium.woff2') format('woff2');
}

@font-face {
    font-family: IRANSansXFaNum;
    font-style: normal;
    font-weight: 600;
    src: url('assets/fonts/Woff/IRANSansXFaNum-DemiBold.woff') format('woff'),
    url('assets/fonts/Woff2/IRANSansXFaNum-DemiBold.woff2') format('woff2');
}

@font-face {
    font-family: IRANSansXFaNum;
    font-style: normal;
    font-weight: 800;
    src: url('assets/fonts/Woff/IRANSansXFaNum-ExtraBold.woff') format('woff'),
    url('assets/fonts/Woff2/IRANSansXFaNum-ExtraBold.woff2') format('woff2');
}

@font-face {
    font-family: IRANSansXFaNum;
    font-style: normal;
    font-weight: 900;
    src: url('assets/fonts/Woff/IRANSansXFaNum-Black.woff') format('woff'),
    url('assets/fonts/Woff2/IRANSansXFaNum-Black.woff2') format('woff2');
}

@font-face {
    font-family: IRANSansXFaNum;
    font-style: normal;
    font-weight: bold;
    src: url('assets/fonts/Woff/IRANSansXFaNum-Bold.woff') format('woff'),
    url('assets/fonts/Woff2/IRANSansXFaNum-Bold.woff2') format('woff2');
}

@font-face {
    font-family: IRANSansXFaNum;
    font-style: normal;
    font-weight: normal;
    src: url('assets/fonts/Woff/IRANSansXFaNum-Regular.woff') format('woff'),
    url('assets/fonts/Woff2/IRANSansXFaNum-Regular.woff2') format('woff2');
}

html {
    @apply font-body
}

@tailwind utilities;

@layer components {

    .btn-red {
        @apply bg-red-500 dark:bg-red-600 hover:bg-red-600 dark:hover:bg-red-800 flex flex-row justify-center items-center gap-1 px-2 py-1 rounded  border-none;
    }

    .btn-green {
        @apply bg-green-500 dark:bg-green-800 hover:bg-green-400 dark:hover:bg-green-700 flex flex-row justify-center items-center gap-1 px-2 py-1 rounded  border-none;
    }

    .btn-blue {
        @apply bg-blue-500 dark:bg-blue-500 hover:bg-blue-800 dark:hover:bg-blue-700 flex flex-row justify-center items-center gap-1 px-2 py-1 rounded  border-none;
    }

    .btn-teal {
        @apply bg-teal-500 dark:bg-teal-800 hover:bg-teal-400 dark:hover:bg-teal-700 flex flex-row justify-center items-center gap-1 px-2 py-1 rounded  border-none;
    }

    .btn-disable {
        @apply bg-gray-500 dark:bg-gray-800 hover:bg-gray-400 dark:hover:bg-gray-700 flex flex-row justify-center items-center  gap-1 px-2 py-1 rounded  border-none;
    }

    .bg-gray {
        @apply flex flex-col gap-1 md:gap-2 bg-white shadow dark:bg-gray-800 text-gray-900 dark:text-gray-100 dark:shadow-none dark:border dark:border-gray-600 rounded-lg p-2 lg:p-8;
    }

    .Jodit-editor-class {
        @apply dark:bg-gray-800 dark:text-gray-100;
    }

}

@layer base {
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}


